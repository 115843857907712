import { validateImageFile } from "./utils";
import Validator from "./validation";
import debounce from "./debounce";

export default class FormSubmitHandler {
    constructor(option) {
        this.isAttached = false;
        this.option = option;
        this.redirect = option.success_redirect;
        this.pictures = [];

        this.attach();
    }

    attach() {
        if (this.isAttached) return;
    
        const { form_selector, validation, picture_grid_selector, picture_input_selector, 
                picture_load_selector, picture_reset_selector, video_player_selector, 
                video_input_selector, video_load_selector, video_reset_selector } = this.option;
    
        if (form_selector) {
            this.csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute("content")
            this.form = document.querySelector(form_selector);
    
            if (validation) {
                this.validation = validation;
            }
    
            if (picture_grid_selector && picture_input_selector && picture_load_selector && picture_reset_selector) {
                this.picture_grid = document.querySelector(picture_grid_selector);
                this.picture_input = document.querySelector(picture_input_selector);
                this.picture_load_button = document.querySelector(picture_load_selector);
                this.picture_reset_button = document.querySelector(picture_reset_selector);
            }
    
            if (video_player_selector && video_input_selector && video_load_selector && video_reset_selector) {
                this.video_player = document.querySelector(video_player_selector);
                this.video_input = document.querySelector(video_input_selector);
                this.video_load_button = document.querySelector(video_load_selector);
                this.video_reset_button = document.querySelector(video_reset_selector);
            }
    
            this.isAttached = true;
            this.attachEvent();
        } else {
            return;
        }
    }
    

    attachEvent() {
        let _this = this;

        if (_this.picture_load_button) {
            _this.picture_load_button.addEventListener('click', (e) => {
                e.preventDefault();
                this.picture_input.click();
            });
        }
        
        if (_this.video_load_button) {
            _this.video_load_button.addEventListener('click', (e) => {
                e.preventDefault();
                this.video_input.click();
            });
        }
        

        if(_this.picture_input) {
            _this.picture_input.addEventListener('change', async(e) => {
                const selectedFiles = e.target.files;
    
                if(selectedFiles) {
                    for(const file of selectedFiles) {
                        await validateImageFile(file)
                            .then(() => {
                                var item = _this.addImage(file);
    
                                if(item) {
                                    const closeItem = item.querySelector('.btn');
    
                                    closeItem.addEventListener('click', (e) => {
                                        const itemId = e.currentTarget.getAttribute('data-item');
                                        const filename = e.currentTarget.getAttribute('data-name');
    
                                        const element = _this.picture_grid.querySelector(`#${itemId}`);
                                        if(element) {
                                            element.remove();
                                        }
    
                                        const index = _this.pictures.findIndex(item => item.name === filename);
                                        if (index !== -1) {
                                            _this.pictures.splice(index, 1);
                                        }
    
                                    });
                                }
                            })
                            .catch(() => {
                                //
                            })
                    }
                }
                _this.picture_input.value = null;
            });
        }
        
        if(_this.video_input) {
            _this.video_input.addEventListener('change', async(e) => {
                const selectedFile = e.target.files[0]
                
                if(selectedFile) {
                    const videoURL = URL.createObjectURL(selectedFile);
                    _this.video_player.src = videoURL;
    
                    _this.video_player.classList.add('active');
                    _this.video_player.play();
    
                }
            })
        }
 
        if(_this.form) {
            _this.form.addEventListener('submit', debounce((e) => {
                e.preventDefault();
                this.clearErrors();
    
                const url = new URL(_this.form.action);
                
                const data = new FormData(_this.form);
                const token = _this.form.querySelector('input[name="_token"]');
                // if(!token) {
                //     throw new Error('CSRF-TOKEN token hidden input is required');
                // }
    
                _this.pictures.forEach((file, _) => {
                    data.append('pictures[]', file);
                });
    
    
                if(_this.validation) {
                    const item = Object.fromEntries(
                        Object.keys(_this.validation).map(key => [key, data.get(key)])
                    );
    
                    const validator = new Validator(item, _this.validation);
    
                    if(validator.validate()) {
                        _this.submitForm(url, data, token);
                    } else  {
                        this.setError(validator.errors);
                    }
        
                } else {
                    _this.submitForm(url, data, token);
                }
            }, 5000));
        }

        if(_this.picture_reset_button) {
            _this.picture_reset_button.addEventListener('click', () => {
                _this.picture_input.value = "";
                _this.pictures.length = 0;
                _this.picture_grid.innerHTML = "";
            });
        }

        if(_this.video_reset_button) {
            _this.video_reset_button.addEventListener('click', () => {
                _this.video_player.value = "";
                _this.video_player.load();
                _this.video_player.length = 0;
                _this.video_player.classList.remove('active');
                _this.video_input.value = "";
            });
        }
    }

    submitForm(url, data, token) {

        const method = this.form.method || 'POST'; // Get form method, default to POST

        const options = {
            method: method.toUpperCase(),
            headers: {}
        };

        // Only add the body for non-GET methods
        if (method.toUpperCase() !== 'GET') {
            options.body = data;
            options.headers['X-CSRF-TOKEN'] = token.value;
        }

        fetch(url.pathname, options)
        .then(response => response.json())
        .then(data => {
            // Check if the response has a validation error
            if (data.hasOwnProperty('errors')) {
                // Handle validation errors, for example, display them in the UI
                // console.error('Validation Error:', data.errors);
                this.setError(data.errors);
                
            } else {
                // If the response is successful (status code 200), redirect to another page
                window.location.href = this.redirect;
            }
        })
        .catch(error => {
            console.error('Fetch Error:', error);
        });
    }

    clearErrors() {
        const _this = this;
    
        // Remove any existing error messages and styling
        const errorElements = _this.form.querySelectorAll('.alert.alert-danger');
        errorElements.forEach(element => {
            element.remove();
        });
    
        // Remove the has-error class from all form groups
        const formGroups = _this.form.querySelectorAll('.form-group');
        formGroups.forEach(formGroup => {
            formGroup.classList.remove('has-error');
        });
    }

    setError(errors) {
        const _this = this;

        Object.keys(errors).forEach(field => {
            const messages = errors[field];

            if(field.startsWith('pictures') || field == 'primary_image') {
                var alert = document.createElement('div');
                var paragraph = document.createElement('p');
                alert.className = 'alert alert-danger';
                paragraph.textContent = messages;
                alert.appendChild(paragraph);
                _this.form.insertBefore(alert, _this.form.firstChild);
    
            } else {

                const inputElement = _this.form.querySelector(`[name="${field}"]`);
            
                if(inputElement) {
                    // Find the invalid-feedback span next to the input
                    const invalidFeedback = inputElement.parentElement.querySelector('.invalid-feedback');
            
                    // Update the span text with the first error message
                    if (invalidFeedback) {
                        invalidFeedback.querySelector('span').textContent = messages[0];
            
                        // Find the parent form-group and add the has-error class
                        const formGroup = inputElement.closest('.form-group');
                        if (formGroup) {
                            formGroup.classList.add('has-error');
                        }
                    }
                }

            }
        });
        
    }

    addImage(file) {
        let _this = this;

        const id = new Date().getTime();
        const item = document.createElement("div");
        item.id = `item_${id}`;
        item.classList.add("picture-grid-item")
        item.innerHTML = `
            <img src="${URL.createObjectURL(file)}"/>
            <a class="btn btn-icon btn-sm btn-danger" data-item="item_${id}" data-name="${file.name}">
                <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-x" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                    <path d="M18 6l-12 12"></path>
                    <path d="M6 6l12 12"></path>
                </svg>
            </a>
            <div class="form-checkbox">
                <input type="radio" class="form-check-input" name="primary_image" value="${file.name}"/>
                <label class="form-check-label">PRIMARY</label>
            </div>
        `

        _this.picture_grid.appendChild(item);
        _this.pictures.push(file);

        return item;
    }
}